import {derived, writable} from "@square/svelte-store"

let pricingUpdated = false
const pSettings = writable<PricingCalculatorSettings>({
    calculatorSettings: {},
    pricingSettings: {
        priceForInfiniteDiariesForDay: null
    }
})

export type PricingCalculatorSettings = {
    calculatorSettings: Record<number, CalculatorSettings>
    pricingSettings: PricingSettings
}

export type PricingSettings = {
    priceForInfiniteDiariesForDay: number | null
}

export type CalculatorSettings = {
    diaryCount: number
    pricePerDiaryDay: number
    priceInfinitePerDay: number
    totalDiscountValue: number
}

export const updatePricingIfEmpty = (): void => {
    if (pricingUpdated) {
        return
    }

    pricingUpdated = true

    // @ts-ignore
    fetch(window._routes.pricingCalculator.priceSettings)
    .then(r => r.json())
    .then(response => pSettings.set(response))
}

export const calculatorPricingSettings = derived<typeof pSettings, Record<number, CalculatorSettings>>(pSettings, hSettings => hSettings.calculatorSettings)
export const pricingSettings = derived<typeof pSettings, PricingSettings>(pSettings, hSettings => hSettings.pricingSettings)

export const dayTemplates = [
    {
        "name": "Vlastní",
        "day": null,
    },
    {
        "name": "Týden",
        "day": 7,
    },
    {
        "name": "Měsíc",
        "day": 30,
    },
    {
        "name": "Čtvrtletí",
        "day": 90,
    },
    {
        "name": "Půlroku",
        "day": 182,
    },
    {
        "name": "Rok",
        "day": 365,
    },
]